import React from "react";
import { ReactComponent as Stop } from '../assets/stop.svg';

const StopButton = ({ onClose }) => {
  return (
    <button
      className="video-react-control video-react-button video-react-stop"
      tabIndex="0"
      onClick={onClose}
    >
      <Stop />
    </button>
  );
};


export default StopButton
