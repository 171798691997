import React, { useEffect, useState, useCallback  } from 'react';
import { useHistory } from "react-router-dom";
import firebase from '../util/Firebase.js';
import md5 from 'md5';

const db = firebase.database();
const PREFIX = 'https://storage.googleapis.com/ncmcloud1.appspot.com/';

var cams = [];
const getcams = () => new Promise( async (resolve,reject) => {
  const locationsRef = db.ref(`locations`);
  const locationsSnapshot = await locationsRef.once('value');
  const locationsObj = locationsSnapshot.val();
  let camList = [];
  for (const child in locationsObj) {
    const visible = locationsObj[child].visible;
    if(visible){
      const title = locationsObj[child].title;
      const locationCams = locationsObj[child].cams;
      const locationId = child;
      for (const cam in locationCams) {
        camList.push({
          dir: locationCams[cam],
          camId: cam,
          title: title,
          locationId:locationId
        });
      }
    } // end visible

  } // end for locationsObj
  resolve(camList);
});

const getMaxValue = () => {
  const clientOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const todayDate = new Date(Date.now() + clientOffset + 14400000);
  const todayYear = todayDate.getFullYear();
  const todayMonth = (todayDate.getMonth()+1).toString().padStart(2, '0');
  const todayDay = todayDate.getDate().toString().padStart(2, '0');
  const todayHour = todayDate.getHours();
  const todayMinutes = todayDate.getMinutes();
  const camDate = `${todayYear}${todayMonth}${todayDay}`;
  const todayMinutesAll = (todayHour*60)+todayMinutes-5;
  const todayMinutesAllSafe = todayMinutesAll>0?todayMinutesAll:0;
  const maxValue = Math.floor(todayMinutesAllSafe/5);
  return {maxValue,camDate}
};

const getSources = (camList) => {
  const {maxValue, camDate} = getMaxValue()
  let sourcesObj = {}
  for (const cam of camList) {
    const key = md5(`${cam.camId}x${camDate}`);
    const imgSrc = getImagePath(key,maxValue);
    sourcesObj[cam.camId] = imgSrc;
  }
  return sourcesObj
};

const getImagePath = (key,i) =>  `${PREFIX}${key}-i-th-${(i*5).toString().padStart(5, '0')}.jpg`;

export default () => {
  //const [cams, setCams] = useState([]);
  const [sources, setSources] = useState();
  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  const openCam = (cam) => {
    history.push(`/${cam.locationId}/${cam.camId}/today`)
  }

  const requestInterval = (fn, delay) => {

  	var start = new Date().getTime();
  	var handle = {};

  	const loop = () => {
  		var current = new Date().getTime(),
  			delta = current - start;

  		if(delta >= delay) {
  			//update();
        fn.call();
  			start = new Date().getTime();
  		}

  		handle.value = requestAnimationFrame(loop);
  	};

  	handle.value = requestAnimationFrame(loop);
  	return handle;
  }

  useEffect(() => {
    const start = async () => {
      cams = await getcams();
      const sourcesList = getSources(cams)
      await setSources(sourcesList)
      await setLoaded(true)
    }

    start()
    const intervalId = requestInterval(()=>{
      const sourcesList = getSources(cams)
      setSources(sourcesList)
      console.log('camsList',cams);
      console.log('sourcesList',sourcesList);
    },5 * 60 * 1000);

    return () => cancelAnimationFrame(intervalId.value)
  },[]);

  return (
      <ul id="locations_list">
      {
        loaded && cams.map(cam => (
          <li key={cam.camId} onClick={() => openCam(cam)} >
            <div>
              <img src={sources[cam.camId]} />
              <p>{ cam.title + ' - ' + cam.dir }</p>
            </div>
          </li>
        ))
      }
      </ul>
  );
}
