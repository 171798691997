import React, {useState} from "react";
import app from '../util/Firebase.js';
import LocationsMap from '../components/LocationsMap'
import Header from '../components/Header'
import LocationsList from '../components/LocationsList'

const Home = () => {
  const [expand, setExpand] = useState(false);
  //const [mapTheme, setMapTheme] = useState(false);

  const expandToggle = () => {
    setExpand(v => !v);
    console.log('expanding');
  };

  return (
    <>
      {/*<h1>Home</h1>
      <button onClick={() => app.auth().signOut()}>Sign out</button>*/}
      <Header onExpand={expandToggle} />
      <LocationsMap  />
      {/*<LocationsList expand={expand} onChange={expandToggle} />*/}
    </>
  );
};

export default Home;
