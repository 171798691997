import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "react-router-dom";
import logo from '../assets/logowhitesmall.png';
import { useHistory } from "react-router-dom";

export default ({ page }) => {
  const history = useHistory();
  const isLocations = page == 'locations';

  return (
    <div id="header">
      <AppBar position="static">
        <Toolbar>
          <Link to="/logout" className="link">Logout</Link>
          <img id="logo" src={logo} />
          { !isLocations &&
            <IconButton id="menu-button" onClick={()=> history.push(`/locations`)} aria-label="menu">
              <svg aria-label="Apps" viewBox="0 0 24 24" className="grommet-icon icon-map-header"><path fill="none" stroke="#000" strokeWidth="2" d="M19,5 L21,5 L21,3 L19,3 L19,5 Z M11,5 L13,5 L13,3 L11,3 L11,5 Z M3,5 L5,5 L5,3 L3,3 L3,5 Z M19,13 L21,13 L21,11 L19,11 L19,13 Z M11,13 L13,13 L13,11 L11,11 L11,13 Z M3,13 L5,13 L5,11 L3,11 L3,13 Z M19,21 L21,21 L21,19 L19,19 L19,21 Z M11,21 L13,21 L13,19 L11,19 L11,21 Z M3,21 L5,21 L5,19 L3,19 L3,21 Z"></path></svg>
            </IconButton>
          }
          { isLocations &&
            <IconButton onClick={()=> history.push(`/`)}  aria-label="map">
              <svg aria-label="MapLocation" viewBox="0 0 24 24" className="grommet-icon icon-map-header"><path fill="none" strokeWidth="2" d="M17.5,6.5 L23,9 L23,22 L16,19 L8,22 L1,19 L1,6 L6,8 M16,19 L16,12 M8,22 L8,12 M12,16.2727273 C12,16.2727273 6,11.5 6,7 C6,3.25 9,1 12,1 C15,1 18,3.25 18,7 C18,11.5 12,16.2727273 12,16.2727273 Z M13,7 C13,6.44766667 12.5523333,6 12,6 C11.4476667,6 11,6.44766667 11,7 C11,7.55233333 11.4476667,8 12,8 C12.5523333,8 13,7.55233333 13,7 Z"></path></svg>
            </IconButton>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}
