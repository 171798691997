import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from "react-router-dom";

export default (props) => {
  const history = useHistory();

  const selectCamera = (newCamId) => {
    history.push( `/${props.locationId}/${newCamId}/${props.camDate}`);
    props.onClose()
  }

  return (
    <Dialog onClose={props.onClose} open={props.open} id="camList">
       <DialogTitle id="simple-dialog-title">Select Camera</DialogTitle>
       <List>
         { props.camList.map( (cam,i) => (
             <ListItem button onClick={() => props.camId != cam.camId && selectCamera(cam.camId)} key={i} className={props.camId == cam.camId ? 'active':''}>
               <ListItemAvatar>
                 <svg aria-label="Video" viewBox="0 0 24 24" className="grommet-icon icon-camera"><path fill="none"  strokeWidth="2" d="M15,9 L23,5 L23,19 L15,15 L15,9 Z M1,5 L15,5 L15,19 L1,19 L1,5 Z"></path></svg>
               </ListItemAvatar>
               <ListItemText primary={cam.dir} />
             </ListItem>
           ))
         }
       </List>
     </Dialog>
  );
}
