import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default (props) => {
  return (
    <Drawer anchor="right" variant="permanent" id="controlsPanel">
      <List>
        <ListItem button onClick={ props.onClickCam }>
          <ListItemIcon><svg aria-label="Video" viewBox="0 0 24 24" className="grommet-icon icon-camera"><path fill="none"  strokeWidth="2" d="M15,9 L23,5 L23,19 L15,15 L15,9 Z M1,5 L15,5 L15,19 L1,19 L1,5 Z"></path></svg></ListItemIcon>
          <ListItemText primary="Camera" />
        </ListItem>
        <ListItem button onClick={ props.onClickAspect } id="aspectRatioButton">
          <ListItemIcon>{ props.aspectRatio ?
            <svg aria-label="Expand" viewBox="0 0 24 24" className="grommet-icon icon-expand" ><path fill="none"  strokeWidth="2" d="M10,14 L2,22 M1,15 L1,23 L9,23 M22,2 L14,10 M15,1 L23,1 L23,9"></path></svg>
            :
            <svg aria-label="Contract" viewBox="0 0 24 24" className="grommet-icon icon-expand"><path fill="none"  strokeWidth="2" d="M2,14 L10,14 L10,22 M1,23 L10,14 M23,1 L14,10 M22,10 L14,10 L14,2"></path></svg>
            }
            </ListItemIcon>
          <ListItemText primary={props.aspectRatio ? 'Full':'Framed'} />
        </ListItem>
        <ListItem button disabled={ props.todayHour < 3 || !props.vidLoaded } onClick={ props.onClickPlay }>
          <ListItemIcon> { /*showVid ?  <CancelPresentationIcon /> : <PlayArrowIcon />*/}
          { props.showVid ?
            <svg aria-label="Stop" viewBox="0 0 24 24" className="grommet-icon icon-play"><rect width="16" height="16" x="4" y="4" fill="none"  strokeWidth="2"></rect></svg>
            :
            <svg aria-label="Play" viewBox="0 0 24 24" className="grommet-icon icon-play"><polygon fill="none"  strokeWidth="2" points="3 22 21 12 3 2"></polygon></svg>
          }
          </ListItemIcon>
          <ListItemText primary={props.vidLoaded ? (props.showVid ?'Stop':'Play'):'Generating...'} />
        </ListItem>

        <ListItem button disabled={ props.todayHour < 3 || !props.vidLoaded} onClick={ props.onClickDownload }>
          <ListItemIcon><svg aria-label="Download" className="grommet-icon icon-download" viewBox="0 0 24 24"><path fill="none"  strokeWidth="2" d="M1,17 L1,23 L23,23 L23,17 M12,2 L12,19 M5,12 L12,19 L19,12"></path></svg></ListItemIcon>
          <ListItemText primary="Download" />
        </ListItem>

        <ListItem button onClick={ props.onClickMap }>
          <ListItemIcon><svg aria-label="MapLocation" viewBox="0 0 24 24" className="grommet-icon icon-map"><path fill="none" stroke="#000" strokeWidth="2" d="M17.5,6.5 L23,9 L23,22 L16,19 L8,22 L1,19 L1,6 L6,8 M16,19 L16,12 M8,22 L8,12 M12,16.2727273 C12,16.2727273 6,11.5 6,7 C6,3.25 9,1 12,1 C15,1 18,3.25 18,7 C18,11.5 12,16.2727273 12,16.2727273 Z M13,7 C13,6.44766667 12.5523333,6 12,6 C11.4476667,6 11,6.44766667 11,7 C11,7.55233333 11.4476667,8 12,8 C12.5523333,8 13,7.55233333 13,7 Z"></path></svg>
          </ListItemIcon>
          <ListItemText primary="Map" />
        </ListItem>
        <ListItem button onClick={ props.onClickLocations }>
          <ListItemIcon><svg aria-label="Apps" viewBox="0 0 24 24" className="grommet-icon icon-locations"><path fill="none" stroke="#000" strokeWidth="2" d="M19,5 L21,5 L21,3 L19,3 L19,5 Z M11,5 L13,5 L13,3 L11,3 L11,5 Z M3,5 L5,5 L5,3 L3,3 L3,5 Z M19,13 L21,13 L21,11 L19,11 L19,13 Z M11,13 L13,13 L13,11 L11,11 L11,13 Z M3,13 L5,13 L5,11 L3,11 L3,13 Z M19,21 L21,21 L21,19 L19,19 L19,21 Z M11,21 L13,21 L13,19 L11,19 L11,21 Z M3,21 L5,21 L5,19 L3,19 L3,21 Z"></path></svg>
          </ListItemIcon>
          <ListItemText primary="Grid" />
        </ListItem>
        <ListItem button onClick={props.onClickCalendar}>
          <ListItemIcon>
          <svg aria-label="Plan" viewBox="0 0 24 24" className="grommet-icon icon-calendar"><path fill="none" strokeWidth="2" d="M18,4 L18,0 L18,4 Z M7,18 L5,18 L7,18 Z M19,18 L9,18 L19,18 Z M7,14 L5,14 L7,14 Z M19,14 L9,14 L19,14 Z M6,4 L6,0 L6,4 Z M1,9 L23,9 L1,9 Z M1,23 L23,23 L23,4 L1,4 L1,23 Z"></path></svg>
          </ListItemIcon>
          <ListItemText primary="Calendar" />
        </ListItem>
        <ListItem button onClick={ props.onClickControls }>
          <ListItemIcon>{ props.controls ?
            <svg aria-label="FormNext" viewBox="0 0 24 24" className="grommet-icon" ><polyline fill="none"  strokeWidth="2" points="9 6 15 12 9 18"></polyline></svg>
             :
           <svg aria-label="FormPrevious" viewBox="0 0 24 24" className="grommet-icon" ><polyline fill="none"  strokeWidth="2" points="9 6 15 12 9 18" transform="matrix(-1 0 0 1 24 0)"></polyline></svg>
          }</ListItemIcon>
          <ListItemText primary="Hide" />
        </ListItem>

      </List>
    </Drawer>
  );
}
