const marks = [ ...Array(96).keys() ].map((v,i)=> {
  return {
    value: i * 3,
    label:
      i == 0 || i == 95 || i % 4 != 0
      ? '' : i / 4
  }
});

export default marks;
