import React , { useState, useRef, useEffect, useCallback } from 'react';
import { Link, useParams, useHistory, useLocation } from "react-router-dom";

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Slider from '@material-ui/core/Slider';
import LinearProgress from '@material-ui/core/LinearProgress';
import CP from '@material-ui/core/CircularProgress';

import {
  Player,
  ControlBar,
  BigPlayButton,
  PlayToggle,
  Shortcut,
  DurationDisplay,
  TimeDivider,
  VolumeMenuButton,
  ForwardControl,
  ReplayControl,
  PlaybackRateMenuButton,
  FullscreenToggle
} from 'video-react';
import 'video-react/dist/video-react.css';
import StopButton from '../components/StopButton';

import Controls from '../components/Controls';
import CamList from '../components/CamList';
import DatePickerComp2 from '../components/DatePickerComp2';
import md5 from 'md5';
import loadImg from '../util/LoadImg';
import marks from '../util/Marks';
import logoGold from '../assets/logo_gold2.png';
import { ReactComponent as Close } from '../assets/close.svg';

import firebase from '../util/Firebase.js';
const db = firebase.database();

const PREFIX = 'https://storage.googleapis.com/ncmcloud1.appspot.com/';

export default () => {
  const [value, setValue] = useState(144)
  const [timeText, setTimeText] = useState('12:00')
  const [loading, setLoading] = useState(true);
  const [bgLoading, setBgLoading] = useState(false);
  const [bgImage, setBgImage] = useState()
  const [showPreview, setShowPreview] = useState(false)
  const [datePicker, setDatePicker] = useState(false)
  const [showVid, setShowVid] = useState(false)
  const [showSwitchCam, setShowSwitchCam] = useState(false)
  const [camDir, setCamDir] = useState('w')
  const [startTime, setStartTime] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [controls, setControls] = useState(true);
  const [camList, setCamList] = useState([]);
  const [locationInfo, setLocationInfo] = useState({});
  //const [address, setAddress] = useState('');

  const myVideo = useRef(null);
  const history = useHistory();
  const { locationId, camId, camDate } = useParams();
  const location = useLocation();

  const getDateStr = s => `${s.slice(0, 4)}-${s.slice(4,6)}-${s.slice(6,8)}`;

  const camDateStr =  getDateStr(camDate);
  const camDateObj = new Date(camDateStr+'T00:00');

  const camDateValue = camDateObj.getTime();
  const dateStringBase = camDateObj.toString();
  const currentDate = dateStringBase.slice(4,15);

  const valueMinutes = value * 5;
  const timeHours = Math.floor(valueMinutes / 60).toString().padStart(2, '0');
  const timeMinutes = (valueMinutes % 60).toString().padStart(2, '0');
  const currentTime = `${timeHours}:${timeMinutes}`;

  const key = md5(`${camId}x${camDate}`);

  const videoPath = `${PREFIX}${key}-v-fh.mp4`;
  const bgImagePath = i => `${PREFIX}${key}-i-fh-${(i*5).toString().padStart(5, '0')}.jpg`;
  const previewImagePath = i =>  `${PREFIX}${key}-i-th-${(i*5).toString().padStart(5, '0')}.jpg`;
  const previewVid = bgImagePath(140);
  const previewImg = previewImagePath(value);


  const onPageLoad = async () => {
    const locationRef = db.ref(`locations/${locationId}`);
    const locationSnapshot = await locationRef.once('value');

    if (locationSnapshot.exists()){
      setLocationInfo(locationSnapshot.val())
      const cams = locationSnapshot.val().cams
      console.log('locationSnapshot.val().cams',locationSnapshot.val().cams);
      if (!cams[camId]){
        history.push(`/`);
      }
      let camListArr = [];
      Object.keys(cams).forEach((cam) => {
        camListArr.push({
          dir: cams[cam],
          camId: cam
        });
      });
      console.log('locationsSnapshot',camListArr);
      setCamList(camListArr);
    }else{
      history.push(`/`);
    }

    setValue(144);
    setShowVid(false);
    setAspectRatio(1);
    //setControls(true);
    document.documentElement.style.setProperty('--objectfit', `contain`);
    const controlCss = document.documentElement.style.getPropertyValue('--controlsWidth');
    console.log('getPropertyValue', controlCss);
    if (!controlCss || controlCss == 'auto'){
      setControls(true);
    }
    else{
      setControls(false);
    }
    await loadImg(bgImagePath(144));
    setBgImage(bgImagePath(144));
    setLoading(false);
    startLoadImages();
  };

  const startLoadImages = async () => {
    let images = [];
    let a = 144;
    for (var i = 0 ; i < 288 ; i++){
      if (i%2==0){a += i }else{a-=i}
      images[i] = loadImg(previewImagePath(a))
    }
    Promise.all(images)
  };

  const onTouchEnd = async() => {
    setBgLoading(true);
    setShowPreview(false);
    await loadImg(bgImagePath(value))
    console.log('touchend');
    setBgImage(bgImagePath(value));
    setTimeText(currentTime)
    setBgLoading(false);
  }

  const sliderOver = async(e) => {
    const percentage = Math.round(e.clientX/window.innerWidth * 287);
    setShowPreview(true)
    setValue(percentage)
  }

  const downloadVideo = () => {
    console.log('donwloading file', videoPath);
    var link = document.createElement("a");
    link.setAttribute('download', true);
    link.href = videoPath;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  // const goLive = () => {
  //   history.push( `/${locationId}/${camId}/today`);
  // };

  const openVid = ()=>{
    if (!showVid){
      //setBgLoading(true);
      const currentTimeline = Math.floor( valueMinutes / 1440 * 57.6)

      const isVidInit = myVideo.current;
      if (isVidInit){
        myVideo.current.seek(currentTimeline);
      }else{
        setStartTime(currentTimeline)
      }

      setShowVid(true)
      myVideo.current.play();
      setTimeout(()=>{
        //setBgLoading(false);
        console.log('duration',myVideo.current.video.video.duration,currentTimeline);
        if (!isVidInit){
          myVideo.current.seek(currentTimeline);
        }

        //myVideo.current.video.video.currentTime
      }, 150);
    }else {
      setShowVid(false)
    }
  };

  const toggleControls = () => {
    if (controls){
      document.documentElement.style.setProperty('--controlsWidth', `38px`);
    }else{
      document.documentElement.style.setProperty('--controlsWidth', `auto`);
    }
    setControls(v => !v)
  }

  const toggleAspectRatio = () => {
    if (aspectRatio){
      document.documentElement.style.setProperty('--objectfit', `cover`);
    }else{
      document.documentElement.style.setProperty('--objectfit', `contain`);
    }
    setAspectRatio(v => !v)
  }

  const goToMap = () => {
    history.push(`/`);
  }
  const goToLocations = () => {
    history.push(`/locations`);
  }

  useEffect(() => { onPageLoad() }, [location]);

  useEffect( async () => { if (showVid) setShowVid(false) },[value]);


  return (
    <div id="location" >

      { loading && <div  id="loading"> <CP /><span>Loading</span> </div> }

      { bgLoading && <LinearProgress className="bgLoading" />}

      <div className="bgImgBoxBlur"><img src={ bgImage } /></div>
      <div className="bgImgBox"><img src={ bgImage } /></div>

      {
          <Player
            muted
            playsInline
            fluid={false}
            width={'100%'}
            height={'100%'}
            id="timeVid"
            ref={myVideo}
            poster={bgImage}
            src={videoPath}
            startTime={startTime}
            preload="metadata"
            className={showVid?'showvid':''}
          >
            <Shortcut clickable={false} />
            <BigPlayButton position="center"  disabled />
            <ControlBar className="vidCtrl" disableDefaultControls={false}>

              <ReplayControl order={1.3} seconds={10}  />
              <PlayToggle order={3.1} />
              <StopButton order={3.2} onClose={() => setShowVid(false)} />
              <ForwardControl order={3.4} seconds={10}  />
              {/*<PlaybackRateMenuButton rates={[2, 1, 0.5]} order={3.5} />*/}
              <DurationDisplay order={4.1} disabled />
              <TimeDivider order={4.2} disabled />
              <VolumeMenuButton disabled />
              <FullscreenToggle disabled />
              <div className="video-react-close" onClick={() => setShowVid(false)}><Close /></div>
            </ControlBar>
          </Player>
      }

      <Controls
        todayHour={12}
        vidLoaded={true}
        showVid={showVid}
        aspectRatio={aspectRatio}
        controls={controls}
        onClickCam={ ()=>setShowSwitchCam(v => !v) }
        onClickPlay={ openVid }
        onClickCalendar={()=>setDatePicker(true)}
        onClickDownload={downloadVideo}
        onClickAspect={toggleAspectRatio}
        onClickMap={goToMap }
        onClickLocations={goToLocations }
        onClickControls={toggleControls }
      />

      <CamList
        open={showSwitchCam}
        onClose={()=>setShowSwitchCam(false)}
        locationId={locationId}
        camDate={camDate}
        camId={camId}
        camList={camList}
      />

      <div id="LocationTitle">
        <IconButton onClick={() => history.goBack()} className="back-button">
          <svg aria-label="LinkPrevious" viewBox="0 0 24 24" className="grommet-icon icon-back"><path fill="none" strokeWidth="3" d="M2,12 L22,12 M13,3 L22,12 L13,21" transform="matrix(-1 0 0 1 24 0)"></path></svg>
        </IconButton>
        <div className="locationName">
          <span className="title">{locationInfo.title}</span>
          <span className="location">{locationInfo.address}</span>
        </div>
      </div>

      <div id="LocationTitleAr">
        <div className="locationName">
          <span className="title">{locationInfo.titleAr} </span>
          <span className="location">{locationInfo.addressAr}</span>
        </div>
      </div>

      <div id="LocationTime">
        <span className="time">{timeText}</span>
        <span className="date">{currentDate}</span>
      </div>

      <img src={logoGold} id="logoGold" />

      { showPreview &&
          <div className="imgBox" style={{ left: `${value/287*100}%`,marginLeft:`-${value/287*320}px`}}>
            <img src={ previewImg } />
            <div className="dateTime">
              <span className="time">{currentTime}</span>
            </div>
          </div>
      }

      <div id="sliderImages">
        { [ ...Array(24) ]
          .map( (v,i) => {
            const src =  previewImagePath(i*12);
            return (<img src={src} data-key={i} key={i}  />)
          })
        }
      </div>

      <Slider
        max={287}
        id="timeline"
        value={value}
        defaultValue={144}
        marks={marks}
        valueLabelDisplay="off"
        onChange={(e,v)=> setValue(v)}
        color="secondary"
        onMouseEnter={() => setShowPreview(true)}
        onMouseLeave={() => setShowPreview(false)}
        onMouseMove={sliderOver}
        onMouseDown={() => setShowPreview(true)}
        onMouseUp={() => onTouchEnd()}
        onTouchStart={() => setShowPreview(true)}
        onTouchEnd={() => onTouchEnd()}
      />

      <DatePickerComp2 isOpen={datePicker} onClose={()=>setDatePicker(false)} />
    </div>
  )
}
