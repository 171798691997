import React, { useState, useEffect } from "react";
import firebase from '../util/Firebase.js';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';

const db = firebase.database();

export default ({ isOpen, onClose, isToday}) => {

  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(true)
  const [value, setValue] = useState()
  const [min, setMin] = useState()
  const [max, setMax] = useState()
  const [records, setRecords] = useState()

  const { locationId, camId, camDate } = useParams();
  const history = useHistory();


  const datePickerSelect = (d) => {
    console.log(d);
    const year = d.getFullYear();
    const month = ( d.getMonth() + 1 ).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const dateStr = `${year}${month}${day}`;
    onClose()
    history.push( `/${locationId}/${camId}/${dateStr}`);
  };

  const datePickerCancel = () => { onClose() };

  const datePickerChange = (date) => {
    const d = new Date(date.toJSON())
    const year = d.getFullYear();
    const month = ( d.getMonth() + 1 ).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const dateStr = `${year}${month}${day}`;

    const todayStr = getTodayStr()
    const isToday = todayStr == dateStr;
    if (isToday){
      history.push( `/${locationId}/${camId}/today`);
    }
    else{
      history.push( `/${locationId}/${camId}/${dateStr}`);
    }

    onClose()
    setValue(d)
  };


  const getRecords = () => new Promise( async (resolve,reject) => {
    const snapshotRecord = await db.ref('/records/' + camId).once('value');
    const allRecords = snapshotRecord.val();
    console.log('allRecords',allRecords);
    await setRecords(allRecords)
    resolve();
  });

  const getTodayDate = () => {
    const clientOffset = new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(Date.now() + clientOffset + 14400000);
  }

  const getTodayStr = () => {
    const todayDate = getTodayDate();
    const todayYear = todayDate.getFullYear();
    const todayMonth = (todayDate.getMonth()+1).toString().padStart(2, '0');
    const todayDay = todayDate.getDate().toString().padStart(2, '0');
    const todayHour = todayDate.getHours();
    const todayMinutes = todayDate.getMinutes();
    return `${todayYear}${todayMonth}${todayDay}`;
  }

  const getDateStr = s => `${s.slice(0, 4)}-${s.slice(4,6)}-${s.slice(6,8)}`;

  const isDateValid = (date) => {
    console.log('d',date.toJSON());
    //const d = new Date(date)
    //const dateIsoStr = d.toISOString();
    const d = new Date(date.toJSON())
    const year = d.getFullYear();
    const month = ( d.getMonth() + 1 ).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const dateStr = `${year}${month}${day}`;
    //const dateStr = d.toISOString().slice(0,10).replace(/[\.\_\-]+/g, '');
    const todayStr = getTodayStr()
    const isToday = todayStr == dateStr;
    if (isToday) return false;
    return records[dateStr]!=24;
  }

  const getActiveDate = (camDate) => {
    if (camDate=='today'){
      return getTodayDate()
    } else {
      const camDateStr = getDateStr(camDate);
      return new Date(camDateStr+'T00:00');
    }
  }

  useEffect(async () => {
    console.log('camDate',camDate);
    await setValue(getActiveDate(camDate || 'today'))

    try{
      await getRecords();
      setLoading(false);
    }catch (error) {
      console.log(error);
    }

  }, []);

  return (<>
    { !loading &&
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            label="Basic example"
            value={value}
            onChange={datePickerChange}
            shouldDisableDate={isDateValid}
            open={isOpen}
            disableToolbar
            showTodayButton
            todayLabel="Today"
            onClose={onClose}
            className="datapicker-input"
          />
        </MuiPickersUtilsProvider>
   }</>
)};
