import React, { useEffect, useState, useRef } from 'react'
import { render } from 'react-dom'
import Leaflet from 'leaflet';
import { MapContainer, Marker, Popup,Tooltip, TileLayer, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { useHistory } from "react-router-dom";
import layerControl from '../assets/layerControl.jpg';
import firebase from '../util/Firebase.js';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';


const db = firebase.database();

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetina,
    iconUrl: icon,
    shadowUrl: iconShadow
});

const position = [23.8, 54.28]
const layerSatalite='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
const layerColored ='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}';

export default () =>{
  const history = useHistory();
  const [layer, setlayer] = useState(layerSatalite);
  const [locations, setLocations] = useState([]);

  const tileRef = useRef()

  //const getDateStr = s => `${s.slice(0, 4)}-${s.slice(4,6)}-${s.slice(6,8)}`;
  const toggleLayer = () => {
    console.log('layerColored',tileRef.current)

    if (layer == layerSatalite ){
      setlayer(layerColored)
      tileRef.current.setUrl(layerColored)
    }
    else{
      tileRef.current.setUrl(layerSatalite)
      setlayer(layerSatalite)
    }

  }

  const openLocation = (l) => {
    //console.log('l',l);
    // const clientOffset = new Date().getTimezoneOffset()*60*1000;
    // const y = new Date(Date.now() + clientOffset + 14400000 - 86400000);
    // const yStr = `${y.getFullYear()}${y.getMonth()+1}${y.getDate()}`;
    //console.log('e',e);
    //console.log('l',l);
    history.push(`/${l.locationId}/${l.camId}/today`)
  }

  useEffect(async () => {

    // let vHeight = window.innerHeight;
    // document.documentElement.style.setProperty('--vHeight', `${vHeight}px`);
    //
    // window.addEventListener('resize', () => {
    //   let vHeight = window.innerHeight;
    //   document.documentElement.style.setProperty('--vHeight', `${vHeight}px`);
    // })

    const locationsRef = db.ref(`locations`);
    const locationsSnapshot = await locationsRef.once('value');
    //const locationsList = locationsSnapshot.val()
    let locationsList = [];
    locationsSnapshot.forEach((child) => {
      //console.log('child.val()',child.val());
      let lat = child.val().latlng.slice(0,child.val().latlng.indexOf(','));
      let lng = child.val().latlng.slice(child.val().latlng.indexOf(',')+1);
      locationsList.push({
        title:child.val().title,
        locationId: child.key,
        latlng: [lat,lng],
        camId: child.val().default
      });
    });
  //  console.log('locationsSnapshot',locationsList);
    setLocations(locationsList)

  },[]);

  return(
    <>
      <span id="map-toggle" onClick={toggleLayer} style={{direction: (layer == layerSatalite ) ? 'rtl':'ltr'}}>
        <img src={layerControl} />
      </span>
      <MapContainer id="LocationsMap" center={position} zoom={6.5} zoomSnap={1}>
        <TileLayer ref={tileRef} url={layer} attribution='Esri | NCM' />

        {
          !!locations && locations.map( l =>
            <Marker
              position={l.latlng}
              key={l.locationId}
              eventHandlers={{ click: () => openLocation(l) }}
            >
              <Tooltip direction="top" permanent >{l.title}</Tooltip>
            </Marker>
          )
        }
      </MapContainer>
    </>
)}


function TileControl() {
  const map = useMap()
  console.log('map center:', map)
  //map.TileLayer.setUrl(layerColored)
  return null
}
