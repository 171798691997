import React, {useState} from 'react';
import './style/index.scss';
import { BrowserRouter as Router,Switch, Route } from "react-router-dom";
import Container from '@material-ui/core/Container';
import firebase from './util/Firebase.js';

import Location from './pages/Location'
import LocationToday from './pages/LocationToday'
import CP from '@material-ui/core/CircularProgress';

import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Locations from "./pages/Locations";
import { AuthProvider } from "./util/Auth";
import PrivateRoute from "./components/PrivateRoute";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   // light: will be calculated from palette.primary.main,
    //   //main: '#f6f6f6',
    //   // dark: will be calculated from palette.primary.main,
    //   // contrastText: will be calculated to contrast with palette.primary.main
    // },
    secondary: {
      //light: '#0066ff',
      main: '#1a90ff',
      // dark: will be calculated from palette.secondary.main,
      //contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    //contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    //tonalOffset: 0.2,
  },
});


function App() {
  {/*const [loading, setLoading] = useState(false);*/}

  return (
    <AuthProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <div className="App">
            {/*{ loading && <div  id="loading"> <CP /><span>Loading</span> </div> }*/}
            <main>
                <Switch>
                  <PrivateRoute exact path="/" component={Home} />
                  <Route exact path="/login" component={Login} />
                  <PrivateRoute exact path="/logout" component={Logout} />
                  <PrivateRoute exact path="/locations" component={Locations} />
                  <PrivateRoute exact path="/:locationId/:camId/today" component={LocationToday} />
                  <PrivateRoute exact path="/:locationId/:camId/:camDate" component={Location} />

                </Switch>
            </main>
          </div>
        </ThemeProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
